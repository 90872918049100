:root {
    --main: #0082ff;
    --dark-blue: #162d5d;
    --hover-blue: #dfe9ff;
    --hover-grey: #b9b9b9;
    --brand-orange: #ee5c33;
    --brand-green: #00a780;
    --green-hover: #14d0a5;
    --main-half: #dfe9ff;
    --main-light: rgb(88 79 255 / 12%);
    --main-hover: rgb(88 79 255 / 6%);
    --text-main: #2b2b35;
    --red-1: #d64444;
    --grey-0: #71718a;
    --grey-1: #737f8b;
    --grey-2: #e2e8f0;
    --grey-3: #54545c;
    --grey-4: #f6f6f6;
    --grey-5: #545464;
    --grey-6: #8a8f98;
    --grey-7: #b5bcca;
    --grey-8: #2b2b35;
    --input-bg: #f8fafc;
    --glitterly-gradient: linear-gradient(43deg, #00d3ce 0%, #007bdd 22%, #8449f2 42%, #ea5fd1 56%, #ff008f 87%);
    /* filters */
    --grey-scale-filter: sepia(30%) brightness(110%) contrast(110%) grayscale(100%);

    --shadow-color: 215deg 12% 57%;
    --shadow-elevation-low: 0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.04),
        0.4px 0.9px 1.4px -0.2px hsl(var(--shadow-color) / 0.2),
        0.9px 1.8px 2.9px -0.4px hsl(var(--shadow-color) / 0.36);
    --shadow-elevation-medium: 0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.04),
        1.1px 2.1px 3.4px -0.1px hsl(var(--shadow-color) / 0.17),
        2.2px 4.4px 7.1px -0.2px hsl(var(--shadow-color) / 0.29),
        4.5px 9.1px 14.7px -0.4px hsl(var(--shadow-color) / 0.42);
    --shadow-elevation-high: 0.3px 0.5px 0.8px hsl(var(--shadow-color) / 0.04),
        2px 4px 6.5px hsl(var(--shadow-color) / 0.1), 3.6px 7.1px 11.5px -0.1px hsl(var(--shadow-color) / 0.15),
        5.2px 10.5px 17px -0.1px hsl(var(--shadow-color) / 0.21),
        7.4px 14.8px 24px -0.2px hsl(var(--shadow-color) / 0.27),
        10.4px 20.8px 33.7px -0.3px hsl(var(--shadow-color) / 0.33),
        14.6px 29.1px 47.1px -0.3px hsl(var(--shadow-color) / 0.39),
        20.3px 40.5px 65.6px -0.4px hsl(var(--shadow-color) / 0.44);
}

body {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    /* Prevent safari double tap zooming */
    touch-action: manipulation;
    overscroll-behavior: none;
}

.hover-pointer:hover {
    cursor: pointer;
}
.react-tiny-popover-container {
    z-index: 1;
}

.gradient-base {
    background-clip: text !important;
    -webkit-text-fill-color: transparent;
}

.initial-loader {
    position: absolute;
    top: calc(50% - 4em);
    left: calc(50% - 4em);
    width: 2rem;
    height: 2rem;
    border: 6px solid var(--main-light);
    border-left: 6px solid var(--main);
    border-radius: 50%;
    animation: load8 1.1s infinite linear;
    transition: opacity 0.3s;
}

.loader--hide {
    display: none;
}

@keyframes load8 {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

.z-index-3 {
    z-index: 3;
}

input[type='checkbox']:checked + div {
    background-color: var(--main) !important;
}

.overflow-hidden-row {
    overflow: hidden;
}

.react-responsive-modal-modal {
    margin: 0 !important;
    border-radius: 6px;
    max-width: unset !important;
}
.no-padding {
    padding: 0 !important;
}
.react-tiny-popover-container {
    z-index: 13;
}

.moveable-size-value {
    background-color: var(--red-1);
    color: white !important;
    border-radius: 4px;
    padding: 0 2px;
}

.selecto-selection {
    opacity: 0;
}

.moveable-locked .moveable-line.moveable-direction {
    background: var(--red-1) !important;
}

.giphy-gif:hover {
    cursor: pointer;
}

body > div.intercom-lightweight-app > div {
    left: 5px;
}

.react-contexify__item__content {
    padding: 2px 6px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;
    font-size: 14px;
    border-radius: 5px;
    margin: 0 4px;
    transition: all 0.12s;
    z-index: 10;
    font-weight: 500;
    color: #2b2b35;
    width: 190px;
}
.react-contexify__item:not(.react-contexify__item--disabled):hover > .react-contexify__item__content,
.react-contexify__item:not(.react-contexify__item--disabled):focus > .react-contexify__item__content {
    cursor: pointer;
    background-color: var(--hover-blue) !important;
    color: #2b2b35 !important;
}

.infinite-scroll-component__outerdiv {
    content-visibility: auto;
}

.moveable-control-box {
    z-index: 1001;
}

.react-contexify__will-enter--scale,
.react-contexify__will-leave--scale {
    transform-origin: bottom left !important;
}
